import axios from "axios";
import { IMineSupply, ISyntheticCharacter } from "./voucher.api";

export const getUserSupply = (params:IGetUserSupply) => {
  return axios.get("/user-supply", { params });
};

export const getWaitNum = () => {
  return axios.get("/user-supply/wait-num");
};

export const getDetail = (id:any):Promise<UserSupplyDetailApi> => {
  return axios.get(`/user-supply/detail/${id}`)
}

export const checkGoods = (id:any,) => {
  return axios.get(`/user-supply/open-goods/${id}`)
}
export const openGoods = (id:any,params:any) => {
  return axios.post(`/user-supply/open-goods/${id}`,params)
}
export const getRecycle = (page:number,page_size:number,supply_ids?:string) =>{
  return axios.get(`/user-supply/recycle`,{params:{
    page,
    page_size,
    supply_ids,
    }
  })
}
export const getMineSupply = (character?:number):Promise<IMineSupply[]> =>{
  return axios.get(`/user-supply/synthetic`,{params:{
    character
  }})
}

export const postRecycle = (supply_ids:number[]) =>{
  return axios.post(`/user-supply/recycle`,{supply_ids})
}

export const postChoose = (supply_ids:number[],choose:number):Promise<{ id: number; token: string }> =>{
  return axios.post(`/user-supply/synthetic-v2/choose`,{
    supply_ids,
    choose
  })
}
export const getSupplyDetail = (page?:number,page_size?:number)=>{
  return axios.get(`/user-supply/log`,{
    params:{
      page,
      page_size
    }
  })
}
export const syntheticUpgrade = (
  supply_ids:number[]
): Promise<ISyntheticCharacter> => {
  return  axios.post("/user-supply/synthetic-v2/upgrade", {
    supply_ids
  });
}

interface IGetUserSupply{
  page?:number;
  page_size?:number;
}


export interface UserSupplyDetailApi {
  code_record: string;
  title: string;
  bazaar_lock:number;
  character:CharacterApi;
  cover:string;
  create_time:number;
  detail: string;
  detail_id:string;
  product_id:number;
  id:number;
  is_gold_vip:boolean;
  is_seal: number;
  remark:string;
  status:number;
  support_bazaar:boolean;
  support_recycle:boolean;
  support_synthetic:boolean;
  target_id:number;
  type:number;
  update_time:number
}
export interface CharacterApi{
  background:string;
  color:string;
  text:string;
  value:number;
}