















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import EmptyBox from "@/components/EmptyBox.vue";
import AddressForm from "@/components/UserSupplyAddressForm.vue";
import { IAddress, MineApi,NewIAddress } from "@/api/mine.api";
import { Toast } from "vant";
import BlindBoxApi from "@/api/blind-box.api";
import { observer } from "@/utils/common.util";
import { checkGoods, openGoods } from "@/api/user-supply.api";

@Component({
  components: { AddressForm, EmptyBox },
})
export default class UserSupplyApplyDelivery extends Vue {
  @Prop()
  isComponent!:boolean
  id = this.$route.params.id;
  address: NewIAddress = {
    address_id: 0,
    consignee: "",
    phone: "",
    provinces: '',
    city: '',
    area: '',
    detailed_address: "",
    zip_code: "",
  };

  get show(): boolean {
    return true;
  }

  set show(status: boolean) {
    if (status) return;
    this.$router.replace({
      name: "UserSupplyEntityDetail",
      params: { id: String(this.id) },
    });
  }

  async mounted(): Promise<void> {
    observer("refreshInit", () => this.init());
    await this.init();
  }
  async init() {
    // if (!this.id) return;
    const toast = Toast.loading({ mask: true });
    this.address = await MineApi.getDeliverAddress();
    toast.close();
  }
  @Emit()
  close(address:IAddress|null){
    if(address){
      return address
    }else{
      return
    }
  }
  async addressComplete(params: IAddress): Promise<void> {
     this.address = {
        address_id: params.address_id,
        consignee: params.consignee,
        phone: params.phone,
        provinces: JSON.stringify(params.provinces),
        city: JSON.stringify(params.city),
        area: JSON.stringify(params.area),
        detailed_address: params.detailed_address,
        zip_code: params.zip_code,
     }
    const toast = Toast.loading();
    const result = await openGoods(this.id, this.address)
      .then(() => undefined)
      .catch((err) => err.message);
    toast.close();
    this.checkStatus()
  }
  async checkStatus(){
    const checkRes = await checkGoods(this.id)
    if(checkRes.status == 1){
      this.checkStatus()
    }else if(checkRes.status == -1){
      Toast.fail('提货失败');
      this.$router.go(-1)
    }else if(checkRes.status == 2){
      await this.$router.replace({ name: "mysupply", query: { num: "1",index:"1" } });
    }
  }
}
